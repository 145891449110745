import React, { Component, useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Connect } from "react-redux";
import {
  Link,
  Redirect,
  withRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import settings from "../../reducers/settings";
//import { useNavigate } from "react-router-dom";

import ukflag_r from "../../assets/images/ukflag_r.png";
import usflag_r from "../../assets/images/usflag_r.png";
import indflag_r from "../../assets/images/IndMapFlag.png";
import derbelogo from "../../assets/images/derbelogo.png";

import slide1 from "../../assets/images/slide1.jpg";
import slide2 from "../../assets/images/slide2.jpg";
import slide3 from "../../assets/images/slide3.jpg";
import slide4 from "../../assets/images/slide4.jpg";

import ns_logo from "../../assets/images/ns_logo.png";
import kl_logo from "../../assets/images/kl_logo.png";
import dc_logo from "../../assets/images/dc_logo.png";
import slogo from "../../assets/images/slogo.png";
const initializeState = () => JSON.parse(window.localStorage.getItem("user"));
// class Login extends Component {
const Login = (props) => {
  const location = useLocation();
  const history = useHistory();
  //render() {
  const [country, setCountry] = useState("United States America");
  const [subDomain, setSubDomain] = useState("");
  const [flag, setFlag] = useState("");
  const [logo, setLogo] = useState("");
  const [empId, setEmpId] = useState("");
  const [pass, setPass] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const user = useSelector((state) => state.auth.user);

  //user? useSelector((state) => state.auth.user):useState(initializeState());
  //const [user] = useState(initializeState());
  //const navigate = useNavigate();

  const { dispatch } = useDispatch();

  const countryOnChange = (e) => {
    const country = e.target.value;
    setCountry(country);   
    
  };

  const subDomainOnChange = (e) => {
    const subDomain = e.target.value;
    setSubDomain(subDomain);
   
  };
  const onChangeempId = (e) => {
    const empId = e.target.value;
    setEmpId(empId);
  };

  const onChangePassword = (e) => {
    const pass = e.target.value;
    setPass(pass);
  };

  useEffect(() => {
    // need to get the domain details and the client ip and location details
    //alert(window.location.host);
    if (window.location.host === "localhost:3000") {
      // on select test
      if (country === "United States America") {
        setFlag(usflag_r);
        setLogo(derbelogo);
        //alert(window.location.host);
      } else if (country === "India") {
        setFlag(indflag_r);
        setLogo(derbelogo);
        // alert("countr");
      } else if (country === "United Kingdom") {
        setFlag(ukflag_r);
        setLogo(derbelogo);
        // alert("countr");
      } else {
        setFlag(slogo);
        setLogo(derbelogo);
      }

      if (subDomain === "DebtCare") {
        setLogo(dc_logo);
      } else if (subDomain === "Numel") {
        setLogo(ns_logo);
      } else if (subDomain === "KL") {
        setLogo(kl_logo);
      } else {
        setLogo(derbelogo);
      }
    } else {
      //alert(window.location.host);
      //alert(window.location.hostname.split(".")[1]);
      if (window.location.host.split(".")[0] === "derbe") {
        if (window.location.hostname.split(".")[1] === "in") {
          setFlag(indflag_r);
        } else if (window.location.hostname.split(".")[1] === "us") {
          setFlag(usflag_r);
        } else if (window.location.hostname.split(".")[1] === "uk") {
          setFlag(ukflag_r);
        }else{
          setFlag(indflag_r);       
        }
         setLogo(derbelogo);
      } else {
        if (window.location.hostname.split(".")[2] === "in") {
          setFlag(indflag_r);
        } else if (window.location.hostname.split(".")[2] === "us") {
          setFlag(usflag_r);
        } else if (window.location.hostname.split(".")[2] === "uk") {
          setFlag(ukflag_r);
        }else {
          setFlag(indflag_r);
        }

        if (window.location.hostname.split(".")[0] === "debtcare") {
          setLogo(dc_logo);
        }
        if (window.location.hostname.split(".")[0] === "numel") {
          setLogo(ns_logo);
        }
        if (window.location.hostname.split(".")[0] === "kl") {
          setLogo(kl_logo);
        }
        if (window.location.hostname.split(".")[0] === "admin") {
          setFlag(slogo);
          setLogo(derbelogo);
        }
        //alert("dont have subdoamins");
      }
    }

    if (localStorage.getItem("user")) {
      //<Redirect to="/" />
      props.history.push("/");
    }
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
  });

  const handleRegister = (e) => {
    e.preventDefault();
    // checking the login from API steps

    alert("login" + empId + " , " + pass);
    //setIsLoading = true;
    props.login(empId, pass, props.history);
    //alert(window.location.host);
  };

  return (
    <div className="auth">
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-2">
            <Link className="header-brand" to="/">
              {/* <i className="fe fe-command brand-logo" /> */}
              <img src={flag} height="50" width="50" alt="" />
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className="card-body">
            <form onSubmit={handleRegister}>
              <div className="card-title">Login to your account</div>
              {/* <div className="form-group">
								<select className="custom-select">
									<option>HR Dashboard</option>
									<option>Project Dashboard</option>
									<option>Job Portal</option>
								</select>
							</div> */}
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="empId"
                  aria-describedby="emailHelp"
                  placeholder="Enter Emploee Code"
                  value={empId}
                  onChange={onChangeempId}
                  required={true}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  id="pass"
                  placeholder="Password"
                  value={pass}
                  onChange={onChangePassword}
                  required={true}
                />
                <label className="form-label">
                  <Link className="float-right small" to="/forgotpassword">
                    I forgot password
                  </Link>
                </label>
              </div>
              <div className="form-group">
                <label className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" />
                  <span className="custom-control-label">Remember me</span>
                </label>
              </div>
              <div>
                {
                  // user.roles.map(role =>role)
                }{" "}
              </div>
              <div className="form-footer">
                <button className="btn btn-primary btn-block" type="submit">
                  Click to login
                </button>
              </div>
              <div className="text-center mb-2">
                <label className="form-label center">
                  <Link className="float-middle small" to="/">
                    <span className="center">Version 1.03417</span>
                  </Link>
                </label>
              </div>

              {/* this is for the Subdomain and country test 

              <div className="form-group">
                <label>Country</label>
                <select
                  className="form-control custom-select"
                  id="countryId"
                  onChange={countryOnChange}
                  value={country}
                >
                  <option value={"United States America"}>
                    United States America
                  </option>
                  <option value={"United Kingdom"}>United Kingdom</option>
                  <option value={"Singapore"}>Singapore</option>
                  <option value={"Australia"}>Australia</option>
                  <option value={"Germany"}>Germany</option>
                  <option value={"India"}>India</option>
                </select>
              </div>
              <div className="form-group">
                <label>SubDomain</label>
                <select
                  className="form-control custom-select"
                  id="SubDomainId"
                  onChange={subDomainOnChange}
                  value={subDomain}
                >
                  <option>--</option>
                  <option value={"DebtCare"}>DebtCare</option>
                  <option value={"Numel"}>Numel</option>
                  <option value={"KL"}>KL</option>
                  <option value={"Indra"}>Indra</option>
                  <option value={"admin"}>Admin</option>
                </select>
              </div> */}
            </form>
          </div>

          {/* <div className="text-center text-muted">
							Don't have account yet? <Link to="/signup">Sign Up</Link>
						</div> */}
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={slide1} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>Derbe</h4>
                <p>A FinTech Platform , Gateway to Crisis Management .</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={slide2} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>Employee to Entrepreneur</h4>
                <p>
                  {" "}
                  Transforms an Employee into an Entrepreneur Financial World.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={slide3} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>
                  Start-up recognized by the DPIIT,ISO 9001:2015 certified{" "}
                </h4>
                <p>
                  Ministry of Commerce & Industry and Ministry of Micro, Small
                  and Medium Enterprises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //}
};

function mapStateToProps(state) {
  console.log("mapStateToProps", state);
  return {
    userdata: state.user,
    // page: state.page
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (empId, pass) => dispatch(login(empId, pass)),
    // pageActions: bindActionCreators(pageActions, dispatch),
    // getUser: bindActionCreators(getUser, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

// import React, { Component } from "react";
// import { Redirect } from 'react-router-dom';

// import Form from "react-validation/build/form";
// import Input from "react-validation/build/input";
// import CheckButton from "react-validation/build/button";

// import { connect } from "react-redux";
// import { login } from "../actions/auth";

// const required = (value) => {
//   if (!value) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This field is required!
//       </div>
//     );
//   }
// };

// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.handleLogin = this.handleLogin.bind(this);
//     this.onChangeUsername = this.onChangeUsername.bind(this);
//     this.onChangePassword = this.onChangePassword.bind(this);

//     this.state = {
//       username: "",
//       password: "",
//       loading: false,
//     };
//   }

//   onChangeUsername(e) {
//     this.setState({
//       username: e.target.value,
//     });
//   }

//   onChangePassword(e) {
//     this.setState({
//       password: e.target.value,
//     });
//   }

//   handleLogin(e) {
//     e.preventDefault();

//     this.setState({
//       loading: true,
//     });

//     this.form.validateAll();

//     const { dispatch, history } = this.props;

//     if (this.checkBtn.context._errors.length === 0) {
//       dispatch(login(this.state.username, this.state.password))
//         .then(() => {
//           history.push("/profile");
//           window.location.reload();
//         })
//         .catch(() => {
//           this.setState({
//             loading: false
//           });
//         });
//     } else {
//       this.setState({
//         loading: false,
//       });
//     }
//   }

//   render() {
//     const { isLoggedIn, message } = this.props;

//     if (isLoggedIn) {
//       return <Redirect to="/profile" />;
//     }

//     return (
//       <div className="col-md-12">
//         <div className="card card-container">
//           <img
//             src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
//             alt="profile-img"
//             className="profile-img-card"
//           />

//           <Form
//             onSubmit={this.handleLogin}
//             ref={(c) => {
//               this.form = c;
//             }}
//           >
//             <div className="form-group">
//               <label htmlFor="username">Username</label>
//               <Input
//                 type="text"
//                 className="form-control"
//                 name="username"
//                 value={this.state.username}
//                 onChange={this.onChangeUsername}
//                 validations={[required]}
//               />
//             </div>

//             <div className="form-group">
//               <label htmlFor="password">Password</label>
//               <Input
//                 type="password"
//                 className="form-control"
//                 name="password"
//                 value={this.state.password}
//                 onChange={this.onChangePassword}
//                 validations={[required]}
//               />
//             </div>

//             <div className="form-group">
//               <button
//                 className="btn btn-primary btn-block"
//                 disabled={this.state.loading}
//               >
//                 {this.state.loading && (
//                   <span className="spinner-border spinner-border-sm"></span>
//                 )}
//                 <span>Login</span>
//               </button>
//             </div>

//             {message && (
//               <div className="form-group">
//                 <div className="alert alert-danger" role="alert">
//                   {message}
//                 </div>
//               </div>
//             )}
//             <CheckButton
//               style={{ display: "none" }}
//               ref={(c) => {
//                 this.checkBtn = c;
//               }}
//             />
//           </Form>
//         </div>
//       </div>
//     );
//   }
// }

// function mapStateToProps(state) {
//   const { isLoggedIn } = state.auth;
//   const { message } = state.message;
//   return {
//     isLoggedIn,
//     message
//   };
// }

// export default connect(mapStateToProps)(Login);
